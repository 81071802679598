<template>
  <header class="header-global" :class="{ 'rtl': $i18n.locale === 'fa' }">
    <base-nav class="navbar-main" transparent type="" effect="light" expand>
      <!-- Main Menu Links -->
      <ul class="navbar-nav navbar-nav-hover align-items-lg-center"
        :class="{ 'ml-lg-auto': locale !== 'fa', 'mr-lg-auto': locale === 'fa' }">

      </ul>

      <!-- Social Media and Language Selector -->
      <ul class="navbar-nav align-items-lg-center"
        :class="{ 'ml-lg-auto': locale !== 'fa', 'mr-lg-auto': locale === 'fa' }">
        <li class="nav-item">
          <a class="nav-link nav-link-icon" href="https://www.facebook.com/guniliyazilim/?locale=tr_TR" target="_blank"
            rel="noopener" data-toggle="tooltip" title="Facebook">
            <i class="fa fa-facebook-square"></i>
            <span class="nav-link-inner--text d-lg-none"> Facebook</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link nav-link-icon"
            href="https://www.instagram.com/guniliyazilim/?igsh=MWpoenJsbXo1eWMxcw%3D%3D" target="_blank" rel="noopener"
            data-toggle="tooltip" title="Instagram">
            <i class="fa fa-instagram"></i>
            <span class="nav-link-inner--text d-lg-none"> Instagram</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link nav-link-icon" href="https://www.linkedin.com/company/gunili/?originalSubdomain=tr"
            target="_blank" rel="noopener" data-toggle="tooltip" title="LinkedIn">
            <i class="fa fa-linkedin"></i>
            <span class="nav-link-inner--text d-lg-none"> LinkedIn</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link nav-link-icon" href="https://www.youtube.com/channel/UCE3vBVCLhCCFNRJN4GmaY3Q"
            target="_blank" rel="noopener" data-toggle="tooltip" title="YouTube">
            <i class="fa fa-youtube"></i>
            <span class="nav-link-inner--text d-lg-none"> YouTube</span>
          </a>
        </li>

        <!-- Language Selector -->
        <li class="nav-item">
          <div class="language-dropdown">
            <select class="form-control" @change="changeLanguage($event.target.value)"
              style="margin: 19px 0; padding-left: 5px;">
              <option value="tr" :selected="locale === 'tr'">Türkçe</option>
              <option value="en" :selected="locale === 'en'">English</option>
              <option value="fa" :selected="locale === 'fa'">فارسی</option>
            </select>
          </div>
        </li>
      </ul>
    </base-nav>
  </header>
</template>


<script>
import BaseNav from "@/components/BaseNav";
import CloseButton from "@/components/CloseButton";
import { ref } from "vue";
import { useI18n } from "vue-i18n";

export default {
  components: {
    BaseNav,
    CloseButton
  },
  setup() {
    const { t, locale } = useI18n();
    const toggled = ref(false); // Menü durumu
    const contentId = ref(`navbar-content-${Math.random()}`); // Benzersiz içerik ID'si

    const changeLanguage = (lang) => {
      locale.value = lang;
      localStorage.setItem("user-language", lang);
      console.log(`Dil değiştirildi: ${lang}`);
    };

    // LocalStorage'dan kaydedilmiş dili al
    const savedLanguage = localStorage.getItem("user-language");
    if (savedLanguage) {
      locale.value = savedLanguage;
    }

    const closeMenu = () => {
      toggled.value = false; // Menü kapatılır
      console.log("Menü kapatıldı");
    };

    return {
      changeLanguage,
      t,
      locale,
      toggled,
      closeMenu,
      contentId
    };
  }
};
</script>

<style>
/* Buraya gerekli stil kurallarını ekleyebilirsin */
</style>
