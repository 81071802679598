<template>
    <div>

        <div class="position-relative">
            <!-- shape Hero -->
            <section class="section-shaped my-0">
                <div class="shape shape-style-1 shape-default shape-skew">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div class="container shape-container d-flex">
                    <div class="col px-0">
                        <div class="row">
                            <div class="col-lg-6">
                                <h1 class="display-3  text-white">Sipariş Modülü
                                    <span>Dijital Üretim Takibiyle Verimliliği Artırın</span>
                                </h1>
                                <p class="lead  text-white pb-150">GoldenMES Sipariş Modülü, işletmenizin sipariş
                                    yönetim süreçlerini dijitalleştirerek üretimi daha verimli hale getirir. Bu modül,
                                    siparişlerinizi hızlıca iş emirlerine dönüştürmenizi sağlarken, üretim sürecinin her
                                    aşamasını takip etme imkanı sunar.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- 1st Hero Variation -->
        </div>
        <section class="section section-lg pt-lg-0 mt--150 pb-0">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-12">
                        <div class="row row-grid">
                            <div class="col-lg-4">
                                <card class="border-0" hover shadow body-classes="py-5">
                                    <icon name="ni ni-check-bold" type="primary" rounded class="mb-4">
                                    </icon>
                                    <h6 class="text-primary text-uppercase">Sipariş Yönetimi</h6>
                                    <p class="description mt-3">Kullanıcılar, siparişleri kolayca sisteme ekleyebilir ve
                                        her sipariş otomatik olarak iş emirlerine dönüştürülerek üretime geçiş sağlanır.
                                    </p>
                                    <base-button tag="a" href="#" type="primary" class="mt-4">
                                        İletişime Geç
                                    </base-button>
                                </card>
                            </div>
                            <div class="col-lg-4">
                                <card class="border-0" hover shadow body-classes="py-5">
                                    <icon name="ni ni-check-bold" type="success" rounded class="mb-4">
                                    </icon>
                                    <h6 class="text-success text-uppercase">Üretim Süreci Takibi</h6>
                                    <p class="description mt-3">GoldenMES, üretim sürecinin her aşamasını izlenebilir ve
                                        kontrol edilebilir hale getirir; böylece işletmeler tam kontrol sağlar.</p>
                                    <base-button tag="a" href="#" type="success" class="mt-4">
                                        İletişime Geç
                                    </base-button>
                                </card>
                            </div>
                            <div class="col-lg-4">
                                <card class="border-0" hover shadow body-classes="py-5">
                                    <icon name="ni ni-check-bold" type="warning" rounded class="mb-4">
                                    </icon>
                                    <h6 class="text-warning text-uppercase">Verimlilik ve Performans Analizi</h6>
                                    <p class="description mt-3">Sistemde, üretim verileri raporlanabilir ve analiz
                                        edilebilir. Günlük raporlar sayesinde işletmeler, verimliliği artırma
                                        fırsatlarını belirleyebilir.</p>
                                    <base-button tag="a" href="#" type="warning" class="mt-4">
                                        İletişime Geç
                                    </base-button>
                                </card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section section-lg">
            <div class="container">
                <div class="row row-grid align-items-center">
                    <div class="col-md-6 order-md-2">
                        <img src="img/theme/promo-1.png" class="img-fluid floating">
                    </div>
                    <div class="col-md-6 order-md-1">
                        <div class="pr-md-5">
                            <icon name="ni ni-settings-gear-65" class="mb-5" size="lg" type="success" shadow rounded>
                            </icon>
                            <h3>GoldenMES Sipariş Modülü ile Tam Kontrol</h3>
                            <p>GoldenMES’in sunduğu Sipariş Modülü, işletmenizin üretim sürecini dijital bir platform
                                üzerinden kolayca yönetmenize ve siparişlerinizi anlık olarak takibe almanıza olanak
                                tanır. İşletmenize özel bu çözüm, süreçlerinizi optimize ederek daha etkin ve verimli
                                bir üretim süreci sağlar.</p>
                            <ul class="list-unstyled mt-5">
                                <li class="py-2">
                                    <div class="d-flex align-items-center">
                                        <badge type="success" circle class="mr-3" icon="ni ni-settings-gear-65"></badge>
                                        <h6 class="mb-0">Dijital Sipariş Yönetimi</h6>
                                    </div>
                                </li>
                                <li class="py-2">
                                    <div class="d-flex align-items-center">
                                        <badge type="success" circle class="mr-3" icon="ni ni-settings-gear-65"></badge>
                                        <h6 class="mb-0">Üretim Sürecinde Tam Kontrol</h6>
                                    </div>
                                </li>
                                <li class="py-2">
                                    <div class="d-flex align-items-center">
                                        <badge type="success" circle class="mr-3" icon="ni ni-settings-gear-65"></badge>
                                        <h6 class="mb-0">Verimlilik ve Performans Artışı</h6>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section section section-shaped my-0 overflow-hidden">
            <div class="shape shape-style-1 bg-gradient-warning shape-skew">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div class="container py-0">
                <div class="row row-grid align-items-center">
                    <div class="col-md-6 order-lg-2 ml-lg-auto">
                        <div class="position-relative pl-md-5">
                            <img src="img/ill/ill-2.svg" class="img-center img-fluid">
                        </div>
                    </div>
                    <div class="col-lg-6 order-lg-1">
                        <div class="d-flex px-3">
                            <div>
                                <icon name="ni ni-building" size="lg" class="bg-gradient-white" color="primary" shadow
                                    rounded></icon>
                            </div>
                            <div class="pl-4">
                                <h4 class="display-3 text-white">Dijital Sipariş Yönetimi</h4>
                                <p class="text-white">Kolay Erişim ve Takip: Siparişleri dijital olarak sisteme girerek
                                    hem daha kolay hem de daha hızlı bir şekilde işleyebilir, takip edebilirsiniz.</p>
                            </div>
                        </div>
                        <card shadow class="shadow-lg--hover mt-5">
                            <div class="d-flex px-3">
                                <div>
                                    <icon name="ni ni-satisfied" gradient="success" color="white" shadow rounded></icon>
                                </div>
                                <div class="pl-4">
                                    <h5 class="title text-success">Üretim Sürecinde Tam Kontrol</h5>
                                    <p>Aşama Aşama İzleme: GoldenMES, üretim sürecinin her aşamasında tam kontrol sunar;
                                        siparişten ürün teslimine kadar süreçleri izleyebilir ve yönetebilirsiniz.
                                        Kalite Güvencesi: Aşamalı kontrol ile olası hatalar önlenir ve kalite artırılır.
                                    </p>
                                </div>
                            </div>
                        </card>
                        <card shadow class="shadow-lg--hover mt-5">
                            <div class="d-flex px-3">
                                <div>
                                    <icon name="ni ni-active-40" gradient="warning" color="white" shadow rounded></icon>
                                </div>
                                <div class="pl-4">
                                    <h5 class="title text-warning"> Verimlilik ve Performans Artışı</h5>
                                    <p>Detaylı Raporlama: Üretim verileri anlık olarak raporlanır; bu raporlar,
                                        işletmenin günlük performansını değerlendirmesi ve geliştirme fırsatlarını
                                        belirlemesi için kullanılır.
                                        Verimlilik Analizi: Verimlilik analizleri ile süreçlerde iyileştirme yapabilir,
                                        kaynak kullanımını optimize edebilirsiniz.</p>
                                </div>
                            </div>
                        </card>
                    </div>
                </div>
            </div>
        </section>
        <section class="section section-lg">
            <div class="container">
                <div class="row row-grid align-items-center">
                    <div class="col-md-6">
                        <img src="img/theme/promo-1.png" class="img-fluid floating">
                    </div>
                    <div class="col-md-6">
                        <div class="pr-md-5">
                            <icon name="ni ni-settings-gear-65" class="mb-5" size="lg" type="success" shadow rounded>
                            </icon>
                            <h3>GoldenMES Sipariş Modülü ile Tam Kontrol</h3>
                            <p>GoldenMES’in sunduğu Sipariş Modülü, işletmenizin üretim sürecini dijital bir platform
                                üzerinden kolayca yönetmenize ve siparişlerinizi anlık olarak takibe almanıza olanak
                                tanır. İşletmenize özel bu çözüm, süreçlerinizi optimize ederek daha etkin ve verimli
                                bir üretim süreci sağlar.</p>
                            <ul class="list-unstyled mt-5">
                                <li class="py-2">
                                    <div class="d-flex align-items-center">
                                        <badge type="success" circle class="mr-3" icon="ni ni-settings-gear-65"></badge>
                                        <h6 class="mb-0">Dijital Sipariş Yönetimi</h6>
                                    </div>
                                </li>
                                <li class="py-2">
                                    <div class="d-flex align-items-center">
                                        <badge type="success" circle class="mr-3" icon="ni ni-settings-gear-65"></badge>
                                        <h6 class="mb-0">Üretim Sürecinde Tam Kontrol</h6>
                                    </div>
                                </li>
                                <li class="py-2">
                                    <div class="d-flex align-items-center">
                                        <badge type="success" circle class="mr-3" icon="ni ni-settings-gear-65"></badge>
                                        <h6 class="mb-0">Verimlilik ve Performans Artışı</h6>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>


    </div>
</template>

<script>
import axios from 'axios';
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
    setup() {
        const { t } = useI18n();
        const name = ref('');
        const email = ref('');
        const message = ref('');
        const errorMessage = ref('');

        const sendMail = async () => {
            const formData = {
                name: name.value,
                email: email.value,
                message: message.value,
            };

            try {
                const response = await axios.post('https://your-api.com/send-mail', formData);

                console.log('E-posta başarıyla gönderildi:', response.data);
                resetForm();
                errorMessage.value = ''; // Hata mesajını temizle
            } catch (error) {
                console.error('E-posta gönderim hatası:', error);
                errorMessage.value = 'E-posta gönderirken bir hata oluştu. Lütfen tekrar deneyin.'; // Hata mesajı ayarla
            }
        };

        const resetForm = () => {
            name.value = '';
            email.value = '';
            message.value = '';
        };

        return { t, name, email, message, sendMail, errorMessage };
    },
});
</script>
